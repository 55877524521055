import {Component} from 'react';
import { Form, Button, NavBar, Cell, Calendar, Popup, Picker, Checkbox, CheckboxGroup, Toast, Radio, RadioGroup} from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './recover.css';
import requests from './requests';


export default class Recover extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      dates: [],
      hallsData: [],
      halls: [],
      moviesData: [],
      movies: [],
      id: '',
      hall: '',
      code: '',
      movie: '',
      showTickets: false,
      tickets: [],
      tempTicket: "",
      upload: "N"
    }
  }


  componentDidMount() {
    let data = querystring.stringify();
    requests.post(data, "api/v1/findHall").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let halls = res.data.data;
        let data = [];
        halls.forEach(function (item) {
          data.push(item["CIN_REMARKS"]);
        });
        this.setState({
          hallsData: halls,
          halls: data,
        })
      }
    });
  }

  getDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  handleConfirmMulti(date) {
    let _this = this;
    let data = [];
    data.push(_this.getDate(date));
    this.setState({
      visible: false,
      dates: data,
      id: '',
      hall: '',
      code: '',
      movie: ''
    })
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  handleTabChange(tab) {
  }

  handleChooseClick(item) {
    if (item === "showHalls") {
      if (this.state.halls.length > 0) {
        if (this.state.dates.length === 1) {
          this.setState({
            [item]: true
          });
        } else if (this.state.dates.length > 0) {
          Toast.info('只能选择一个日期');
        } else {
          Toast.info('选择一个日期');
        }
      } else {
        Toast.info('没有选择的影厅');
      }
    } else if (item === "showMovies") {
      if (this.state.movies.length > 0) {
        if (this.state.dates.length === 1) {
          if (this.state.hall) {
            this.setState({
              [item]: true
            });
          } else {
            Toast.info('选择一个影厅');
          }
        } else if (this.state.dates.length > 0) {
          Toast.info('只能选择一个日期');
        } else {
          Toast.info('选择一个日期');
        }
      } else {
        Toast.info('没有选择的场次');
      }
    } else {
      this.setState({
        [item]: true
      });
    }
  }

  handleSearch() {
    if (this.state.id && this.state.code) {
      let date = this.state.dates[0];
      let data = querystring.stringify({date: date, hallId: this.state.id, sessionId: this.state.code});
      requests.post(data, "api/v1/findTicketByDetail").then((res) => {
        if(res.data.base_resp.ret === 0) {
          if (res.data.data.length > 0) {
            this.setState({
              showTickets: true,
              tickets: res.data.data
            })
          } else {
            Toast.info('没有数据');
          }
        }
      });
    } else {
      Toast.info('请选择时间');
    }
  }

  handleHallCancle() {
    this.setState({
      showHalls: false
    });
  }

  handleHallConfirm(value) {
    let id = '';
    this.state.hallsData.forEach(function (item) {
      if (item["CIN_REMARKS"] === value) {
        id = item["CIN_CINEMA_CD"];
      }
    });
    this.setState({
      id: id,
      hall: value,
      code: '',
      movie: '',
      showHalls: false
    });
    let data = querystring.stringify({id: id, date: this.state.dates[0]});
    requests.post(data, "api/v1/findPlanByHall").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let movies = res.data.data;
        let data = [];
        movies.forEach(function (item) {
          data.push(item["FILMNAME"] + item["SHOWSTARTTIME"].split(" ")[1]);
        });
        this.setState({
          moviesData: movies,
          movies: data
        })
      }
    });
  }

  handleMovieCancle() {
    this.setState({
      showMovies: false
    });
  }

  handleMovieConfirm(value) {
    let code = '';
    this.state.moviesData.forEach(function (item) {
      if (item["FILMNAME"] + item["SHOWSTARTTIME"].split(" ")[1] === value) {
        code = item["SESSIONCODE"];
      }
    });
    this.setState({
      code: code,
      movie: value,
      showMovies: false
    });
  }

  handleGroupChange(index) {
    let tempTicket = this.state.tickets[index].IUR_TICKET_NO;
    this.setState({
      tempTicket: tempTicket
    })
  }

  handleTicketsSubmit() {
    let tempTicket = this.state.tempTicket;
    if (tempTicket) {
      let data = querystring.stringify({dates: "", hallId: "", sessionCode: "", ticketCode: tempTicket, upload: this.state.upload});
      requests.post(data, "api/v1/recoverSingleData").then((res) => {
        if(res.data.base_resp.ret === 0) {
          Toast.success('恢复完成');
        }
      });
    }
    this.setState({
      showTickets: false,
      tempTypes: []
    });
  }

  handleTicketsClose() {
    this.setState({
      showTickets: false,
      tempTypes: []
    });
  }

  get_ticket_data() {
    let _this = this;
    let items = this.state.tickets.map(function(item, index) {
      if (item.IUR_ROW_NUM) {
        return (
          <Cell key={index}>
            <Radio name={index}>{item.TKT_PRICE_TYPE}, {item.IUR_TICKET_NO}, {item.IUR_PRICE}, {item.IUR_ROW_NUM}排{item.IUR_COL_NUM}列</Radio>
          </Cell>
        )
      } else {
        return (
          <Cell key={index}>
            <Radio name={index}>{item.TKT_PRICE_TYPE}, {item.IUR_TICKET_NO}, {item.IUR_PRICE}</Radio>
          </Cell>
        )
      }
    });
    if (items.length > 0) {
      return (
        <div className="tickets">
          <RadioGroup onChange={_this.handleGroupChange.bind(_this)}>
            <Cell.Group border={false}>
              {items}
            </Cell.Group>
            <div className="types">
              <Button className="button first" type="default" onClick={_this.handleTicketsClose.bind(_this)} >
                取消
              </Button>
              <Button className="button" type="primary" onClick={_this.handleTicketsSubmit.bind(_this)} >
                恢复
              </Button>
            </div>
          </RadioGroup>
        </div>
      )
    } else {
      return;
    }
  }

  handleUploadChange(names) {
    let upload = "N";
    if (names.length > 0) {
      upload = "Y";
    }
    this.setState({
      upload: upload
    })
  }

  render() {
    return (
      <div className="recover">
        <NavBar title="恢复" leftArrow={false} border={false}/>
        <div className={this.state.showTickets ? "hide" : "active"}>
          <div className="tabs">
            <div className="before">
              <Form>
                <Cell.Group border={false}>
                  <Cell title="选择日期" value={this.state.dates.length > 0 && "选择了" + this.state.dates.length + "个日期"} isLink={true} onClick={this.handleClick.bind(this)} />
                  <Cell title="选择影厅" value={this.state.hall} isLink={true} onClick={this.handleChooseClick.bind(this, "showHalls")} />
                  <Cell title="选择场次" value={this.state.movie} isLink={true} onClick={this.handleChooseClick.bind(this, "showMovies")} />

                  <Calendar
                    minDate={new Date(2020, 12, 1)}
                    maxDate={new Date(2030, 12, 1)}
                    defaultValue={new Date()}
                    style={{ height: 500 }}
                    visible={this.state.visible}
                    poppable={true}
                    onClose={this.handleClick.bind(this)}
                    onConfirm={(dates) => {
                      this.handleConfirmMulti(dates);
                    }}
                  />
                </Cell.Group>
              </Form>
            </div>
            <Checkbox.Group className="upload" onChange={this.handleUploadChange.bind(this)}>
              <Cell.Group>
                <Cell
                  title='恢复不上报'
                  rightIcon={<Checkbox name="Y" />}
                />
              </Cell.Group>
            </Checkbox.Group>
            <div>
              <Button className="button" type="primary" round={true} block={true} onClick={this.handleSearch.bind(this)} >
                查询
              </Button>
            </div>
          </div>
          <Footer pathname="recoverSingle" />
          <Popup visible={this.state.showHalls} position="bottom" round={true} title="选择影厅">
            <Picker columns={this.state.halls} onCancel={this.handleHallCancle.bind(this)} onConfirm={this.handleHallConfirm.bind(this)}>
            </Picker>
          </Popup>
          <Popup visible={this.state.showMovies} position="bottom" round={true} title="选择场次">
            <Picker columns={this.state.movies} onCancel={this.handleMovieCancle.bind(this)} onConfirm={this.handleMovieConfirm.bind(this)}>
            </Picker>
          </Popup>
        </div>
        <div className={this.state.showTickets ? "active" : "hide"}>
          {this.get_ticket_data()}
        </div>
      </div>
    );
  }
}
